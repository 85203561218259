import React, { useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Banner from "../../components/FaqPage/Banner/Banner";
import Faq from "../../components/FaqPage/Faq/Faq";
import ContactUs from "../../components/FaqPage/ContactUs/ContactUs";

const FaqPage = () => {
  const [ currentTab, setCurrentTab ] = useState(0);
  return (
    <HelmetProvider>
      <Helmet>
        <title>EST INVEST · FAQ & Support</title>
        <meta name="title" content="Find Answers to Your Questions with EST INVEST Support"/>
        <meta property="og:title" content="Find Answers to Your Questions with EST INVEST Support"/>
        <meta property="twitter:title" content="Find Answers to Your Questions with EST INVEST Support"/>

        <meta name="description" content="Get fast and accurate answers to your trading-related questions with EST INVEST's dedicated support team. Visit our FAQ section or contact us via phone, email, or live chat to get the assistance you need."/>
        <meta property="og:description" content="Get fast and accurate answers to your trading-related questions with EST INVEST's dedicated support team. Visit our FAQ section or contact us via phone, email, or live chat to get the assistance you need."/>
        <meta property="twitter:description" content="Get fast and accurate answers to your trading-related questions with EST INVEST's dedicated support team. Visit our FAQ section or contact us via phone, email, or live chat to get the assistance you need."/>

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden relative">
        <div className="faq-page-bg"></div>
        <Header />
        <Banner currentTab={currentTab} setCurrentTab={setCurrentTab} />
        <Faq currentTab={currentTab} />
        <ContactUs />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default FaqPage;
