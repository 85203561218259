import React from "react";
import classes from "./Footer.module.scss";
import logo from "../../../assets/img/logo.png";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  const { hostname } = document.location;
  return (
    <footer className="mt container">
      <div className={classes.footer}>
        <div className={classes.logo}>
          <img src={logo} alt="" />
        </div>
        <div className={classes.info}>
          <div className={classes.contacts}>
            <p>{t("footer_1")}</p>
            <a href={`mailto:support@${hostname}`}>
              support@{hostname}
            </a>
            <a href="tel:442081573882">
              +442081573882
            </a>
          </div>
          <div className={classes.docs}>
            <a href="/docs/TERMS_&_CONDITIONS.pdf" target="_blank">
              {t("footer_2")}
            </a>
            <a href="/docs/PRIVACY_POLICY.pdf" target="_blank">
              {t("footer_3")}
            </a>
            <a href="/docs/AML_POLICY.pdf" target="_blank">
              {t("footer_4")}
            </a>
          </div>
        </div>
        <div className={classes.disclaimer}>
          <p className={classes.disclaimerTitle}>{t("footer_5")}</p>
          <p>
            {t("footer_6")}
          </p>
          <p className={classes.rights}>
            {t("footer_7")}
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
